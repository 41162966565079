<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge anzeigen</label>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="year"
              :options="yearDD"
              :clearable="false"
              label="text"
              placeholder="Yahr"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="partnerSelected"
              :options="partnerDD"
              :clearable="false"
              label="text"
              placeholder="Partner auswählen"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="statusSelected"
              :options="statusDD"
              :clearable="false"
              label="text"
              placeholder="Status auswählen"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="statusAiSelected"
              :options="statusAi"
              :clearable="false"
              label="text"
              placeholder="KI ergebnis"
              style="margin-right:15px;"
            />
          </b-col>
        </b-row>
        <b-row
          style="padding-top:10px;"
        >
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            &nbsp;
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="userEmail"
                class="d-inline-block"
                placeholder="Suche nach Email..."
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="vin"
                class="d-inline-block mr-1"
                placeholder="Suche nach FIN..."
              />
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-right justify-content-start mb-1 mb-md-0"
          >
            &nbsp;
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                type="button"
                size="sm"
                variant="outline-primary"
                class="d-inline-block mr-1"
                style="margin-top:0px;"
                @click="exportDataBackoffice();"
              >
                Export CSV Backoffice
              </b-button>
              <b-button
                type="button"
                size="sm"
                variant="outline-primary"
                class="d-inline-block mr-1"
                style="margin-top:0px;"
                @click="exportData(true);"
              >
                Export CSV Pauschale
              </b-button>
              <b-button
                type="button"
                size="sm"
                variant="outline-primary"
                class="d-inline-block mr-1"
                style="margin-top:0px;"
                @click="exportData(false);"
              >
                Export CSV Gemessen
              </b-button>
              <b-spinner
                v-if="openAIprocessActive===true"
                style="width:30px; height:20px; margin-right:20px;"
                variant="primary"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchBonus"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Keine passenden Einträge gefunden"
        style="min-height:700px"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(avatar)="data">
          <b-avatar
            :text="avatarText(fullName(data.item.lastname,data.item.firstname))"
            variant="success"
          />
          <b-link
            :to="{ name: 'bonus-detail', params: { bonusId: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          />
        </template>

        <template #cell(street)="data">
          {{ data.value }}<br>
          {{ data.item.zip }} {{ data.item.city }}
        </template>

        <template #cell(email)="data">
          <feather-icon
            v-if="data.item.customertype==1"
            icon="UserIcon"
            size="16"
            class="align-middle text-body"
          />
          <feather-icon
            v-if="data.item.customertype==2"
            icon="ShieldIcon"
            size="16"
            class="align-middle text-body"
          />
          {{ data.value.substring(0,25) }}<br>
          <div v-if="data.item.customertype==2">
            <b-badge variant="light-primary">
              {{ data.item.companytitle.substring(0,25) }}
            </b-badge>
          </div>
          {{ data.item.lastname }} {{ data.item.firstname }}
          <br>
          <span
            v-if="data.item.openaiconfirmedaddress=='true'"
          >
            <b-badge
              variant="light-info"
              style="text-align:left;"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
                class="align-left text-body"
              />
              {{ data.item.address_street }}<br>{{ data.item.address_zip }} {{ data.item.address_city }}
            </b-badge>
          </span>
          <span
            v-else
            style="font-size: 13px"
          >{{ data.item.address_street }}<br>{{ data.item.address_zip }} {{ data.item.address_city }}
          </span>
        </template>

        <template #cell(vin)="data">
          <b-link
            :to="{ name: 'bonus-detail', params: { bonusId: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <small>{{ data.item.openaimotortype.substring(0,35) }}</small>
            <br>
            <span
              v-if="data.item.openaiconfirmedvin=='true'"
            >
              <b-badge variant="light-info">
                <feather-icon
                  icon="CheckCircleIcon"
                  size="16"
                  class="align-middle text-body"
                />
                {{ data.value }}
              </b-badge>
            </span>
            <span
              v-else
            >
              {{ data.value }}
            </span>
          </b-link>
          <small>
            <b-badge
              v-if="data.item.postprocessstatus==10"
              variant="light-success"
            >
              <b>{{ data.item.openaiattemptnr }}</b>
            </b-badge>
            <b-badge
              v-else
              variant="light-secondary"
            >
              {{ data.item.openaiattemptnr }}
            </b-badge>
            &nbsp;
          </small>
          <small
            v-if="data.item.stringmatch === 'true'"
          >
            <b-badge variant="light-info">
              100
            </b-badge>
          </small>
          <small
            v-else-if="data.item.openaivinmatch > 0"
          >
            <b-badge variant="light-secondary">
              {{ data.item.openaivinmatch }}
            </b-badge>
          </small>
          <div
            v-if="data.item.backendcomment.length>0"
            v-b-tooltip.hover.top.html="data.item.backendcomment"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="align-middle text-body"
            />
            &nbsp;
            {{ data.item.backendcomment.substring(0,35) }}
          </div>
          <div
            v-if="data.item.postprocessmessage.length>0"
            style="font-size:12px; color:red;"
            v-html="data.item.postprocessmessage"
          />
        </template>

        <template #cell(postprocessrecheckconfirmedstatus)="data">
          <div
            v-if="data.item.postprocessconfirmedmessage.length>0"
            style="font-size:12px; color:red;"
            v-html="data.item.postprocessconfirmedmessage"
          />
          <span
            v-if="data.item.postprocessrecheckconfirmedstatus == 10"
          >
            OK
          </span>
        </template>
        <template #cell(currentstatus)="data">
          <b-badge
            :style="getStatusColor(data.value)"
            v-html="getStatusTitle(data.value)"
          />
        </template>

        <template #cell(datefrom)="data">
          {{ data.item.datefrom | moment("DD.MM.YYYY") }} -  {{ data.item.dateto | moment("DD.MM.YYYY") }}
          <br>
          <span style="color:red; font-weight:bold;"><small>{{ compareDates(data.item.datefrom, data.item.openaipermitvalidfrom) }}</small></span>
        </template>

        <template #cell(lastName)="data">
          <b-link
            :to="{ name: 'bonus-detail', params: { bonusId: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.lastName }} {{ data.item.firstName }}
          </b-link>
        </template>

        <template #cell(lastopeneddate)="data">
          {{ data.item.lastopeneddate | moment("DD.MM. YYYY HH:mm") }}
          <br>
          {{ email2string(data.item.lastopenedbyusername) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'bonus-detail', params: { bonusId: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Mehr</span>
            </b-dropdown-item>
            <b-dropdown-divider />

            <b-dropdown-group
              header="Status"
            >
              <b-dropdown-item
                v-for="(value, key, index) in bonusStatus"
                :key="`${ key }-${ index }`"
                @click="updateStatus(data.item.id, key)"
              >
                <span
                  v-if="key!==data.item.currentstatus"
                  class="align-middle ml-50"
                >
                  {{ value }}
                </span>
                <span
                  v-if="key===data.item.currentstatus"
                  class="align-middle ml-50 text-primary"
                  style="font-weight:bold;"
                >
                  {{ value }}
                </span>
              </b-dropdown-item>
            </b-dropdown-group>

            <b-dropdown-divider />
            <b-dropdown-item
              v-if="openAIprocessActive===true"
            >
              AI prozessieren - bitte warten
            </b-dropdown-item>
            <b-dropdown-item
              v-if="openAIprocessActive===false"
              @click="reprocessOpenAi(data.item.id); showOpenAIprocessModal();"
            >
              <feather-icon
                icon="CogIcon"
              />
              <span class="align-middle ml-50">AI prozessieren</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="reprocessRemoveOpenAi(data.item.id)"
            >
              <feather-icon
                icon="CogIcon"
              />
              <span class="align-middle ml-50">AI prozessierung löschen</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="historyOpenAi(data.item.id); showOpenAIprocessModal();"
            >
              <feather-icon
                icon="CogIcon"
              />
              <span class="align-middle ml-50">AI historie</span>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
              @click="deleteBonus(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
              />
              <span class="align-middle ml-50">Löschen</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} bis {{ dataMeta.to }} von {{ dataMeta.of }} Einträge</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBonus"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-openAIprocess"
      ref="openAIprocess-modal"
      title="AI prozessierung"
      ok-title="Schließen"
      ok-only
      size="xl"
    >
      <div
        v-if="openAIprocessActive === true"
        style="text-align: center;"
      >
        <b-spinner /><br>
        Wird geladen ...
      </div>
      <div
        v-if="openAIprocessActive === false"
      >
        <h4>Prompt: </h4>
        <pre>{{ openAIprocessData.prompt }}</pre>
        <h4>Response: </h4>
        <pre v-html="openAIprocessData.response" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink, BButton,
  BDropdown, BDropdownItem, BPagination, BAvatar, BBadge,
  BFormInput, BDropdownDivider, BDropdownGroup, VBTooltip,
  BSpinner, BImg, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ref,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api'

import { createBlob } from 'blob-util'
import moment from 'moment'

// . import { avatarText } from '@core/utils/filter'
import store from '@/store'
import bonusStoreModule from '../bonusStoreModule'

import { bonusStatus, openAiFilter } from '../../../../enums'

export default {
  components: {
    BModal,
    BImg,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownGroup,
    BPagination,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    vSelect,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    showOpenAIprocessModal() {
      this.$refs['openAIprocess-modal'].show()
    },
    hideOpenAIprocessModal() {
      this.$refs['openAIprocess-modal'].hide()
    },
    filterTypeClass() {
      let style = 'd-flex align-items-center justify-content-end mb-1 mb-md-0'
      if ((this.$store.getters['app/currentBreakPoint'] === 'xs') || (this.$store.getters['app/currentBreakPoint'] === 'sm')) {
        style = 'd-flex align-items-center justify-content-start mb-1 mb-md-0'
      }

      return style
    },
  },
  setup(props, ctx) {
    const BONUS_APP_STORE_MODULE_NAME = 'app-bonus'

    // Register module
    if (!store.hasModule(BONUS_APP_STORE_MODULE_NAME)) store.registerModule(BONUS_APP_STORE_MODULE_NAME, bonusStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BONUS_APP_STORE_MODULE_NAME)) store.unregisterModule(BONUS_APP_STORE_MODULE_NAME)
    })

    // . sidebar trigger
    const isAddNewcarsidebarActive = ref(false)
    const perPage = ref(25)
    const totalBonus = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('lastOpenedDate')
    const isSortDirDesc = ref(true)
    const status = ref(1)
    const vin = ref('')
    const partnerDD = ref([{ text: 'Alle Partner', value: '' }])
    const partnerSelected = ref('')
    const statusDD = ref([{ text: 'Alle', value: 0 }])
    const statusSelected = ref(0)
    const userEmail = ref('')
    const openAIprocessActive = ref(false)
    const openAIprocessData = ref({ prompt: '', response: '' })
    const statusAi = ref([])
    const statusAiSelected = ref(0)

    const toast = useToast()
    const refUserListTable = ref(null)

    /*
    const startYear = 2023
    //  const currentYear = new Date().getFullYear()
    //  for now
    const currentYear = 2023

    const year = ref(currentYear)
    const yearDD = ref([])

    for (let i = startYear; i <= currentYear; i += 1) {
      const option = {
        text: `Jahr ${i}`,
        value: i,
      }
      yearDD.value.push(option)
    }
    */

    // manual
    const currentYear = 2024
    const year = ref(currentYear)
    const yearDD = ref([])
    yearDD.value.push({
      text: 'Jahr 2023',
      value: 2023,
    })
    yearDD.value.push({
      text: 'Jahr 2024',
      value: 2024,
    })

    const tableColumns = [
      { key: 'currentstatus', label: 'Status', thStyle: { width: '50px !important' } },
      { key: 'datefrom', label: 'Von - Bis', thStyle: { width: '100px !important' } },
      { key: 'street', label: 'LADEADRESSE', thStyle: { width: '250px !important' } },
      { key: 'email', label: 'Benutzer', thStyle: { width: '130px !important' } },
      { key: 'vin', label: 'FIN', thStyle: { width: '100px !important' } },
      { key: 'postprocessrecheckconfirmedstatus', label: 'Bestätigt-Test', thStyle: { width: '1500px !important' } },
      { key: 'partner', label: 'Partner', thStyle: { width: '200px !important' } },
      { key: 'lastopeneddate', label: 'ZULETZT', thStyle: { width: '200px !important' } },
      { key: 'actions', label: 'Aktionen', thStyle: { width: '80px !important' } },
    ]

    // !! add to sniplets
    Object.entries(bonusStatus).forEach(([key, value]) => {
      const option = {
        text: value,
        value: parseInt(key, 10),
      }
      statusDD.value.push(option)
    })

    Object.entries(openAiFilter).forEach(([key, value]) => {
      const option = {
        text: value,
        value: parseInt(key, 10),
      }
      statusAi.value.push(option)
    })

    const dataMeta = computed(() => {
      const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalBonus.value,
      }
    })

    store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/fetchPartnerDD`)
      .then(response => {
        response.data.forEach(element => {
          const option = {
            text: element.title,
            value: element.value,
          }
          partnerDD.value.push(option)
        })
      })

    const fetchBonus = (ctx, callback) => {
      let partner = ''
      if (typeof (partnerSelected.value.value) !== 'undefined') {
        partner = partnerSelected.value.value
      }

      let bonusStatusVal = 0
      if (typeof (statusSelected.value.value) !== 'undefined') {
        bonusStatusVal = statusSelected.value.value
      }

      let statusAiSelectedVal = 0
      if (typeof (statusAiSelected.value.value) !== 'undefined') {
        statusAiSelectedVal = statusAiSelected.value.value
      }

      let yearVal = currentYear
      if (typeof (year.value.value) !== 'undefined') {
        yearVal = year.value.value
      }

      store
        .dispatch(`${BONUS_APP_STORE_MODULE_NAME}/fetchBonus`, {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          year: yearVal,
          status: status.value,
          vin: vin.value,
          partner,
          bonusStatus: bonusStatusVal,
          statusAi: statusAiSelectedVal,
          email: userEmail.value,
        })
        .then(response => {
          const total = response.data.records
          const cars = response.data.list

          callback(cars)
          totalBonus.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const refetchData = () => {
      refUserListTable.value.refresh()
    }

    const deleteBonus = bonusId => {
      ctx.root.$bvModal
        .msgBoxConfirm('Bitte bestätigen Sie, dass Sie den Bonus löschen möchten.', {
          title: 'Bonus löschen?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/deleteBonus`, { id: bonusId })
              .then(response => {
                if (response.status === 200) {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Daten wurden gelöscht',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  refetchData()
                } else {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Daten wurden nicht gelöscht!',
                      text: response.data.payload.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'warning',
                    },
                  })
                }
              })
              .catch(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Fehler bei der Verbindung zum Server',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              })
          }
        })
    }

    const reprocessOpenAi = bonusId => {
      openAIprocessData.value = { prompt: '', response: '' }
      openAIprocessActive.value = true
      store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/reprocessOpenAi`, { id: bonusId })
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'AI prozessierung OK',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          refetchData()

          openAIprocessData.value = response.data
          openAIprocessActive.value = false
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler bei der Verbindung zum Server',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
          openAIprocessActive.value = false
        })
    }

    const reprocessRemoveOpenAi = bonusId => {
      ctx.root.$bvModal
        .msgBoxConfirm('Bitte bestätigen.', {
          title: 'AI prozessierung löschen?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/reprocessRemoveOpenAi`, { id: bonusId })
              .then(response => {
                if (response.status === 200) {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Daten wurden gelöscht',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  refetchData()
                } else {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Daten wurden nicht gelöscht!',
                      text: response.data.payload.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'warning',
                    },
                  })
                }
              })
              .catch(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Fehler bei der Verbindung zum Server',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              })
          }
        })
    }

    const updateStatus = (bonusId, statusValue) => {
      ctx.root.$bvModal
        .msgBoxConfirm('Status Update bestätigen?', {
          title: 'Update Status',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/updateStatus`, { id: bonusId, status: parseInt(statusValue, 10) })
              .then(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Erfolg',
                    text: 'Status gespeichert.',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                refetchData()
              })
              .catch(() => {
                console.log('error')
              })
          }
        })
    }

    const getStatusTitle = statusId => bonusStatus[parseInt(statusId, 10)]

    const getStatusColor = statusId => {
      let backgroundColor = 'rgba(40, 118, 251, 0.12)'
      let color = '#2876fb'

      if (parseInt(statusId, 10) === 10) {
        backgroundColor = 'rgba(40, 118, 251, 0.12)'
        color = '#2876fb'
      } else if (parseInt(statusId, 10) === 20) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#82868b'
      } else if (parseInt(statusId, 10) === 21) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 25) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 30) {
        backgroundColor = '#2876fb'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 40) {
        backgroundColor = 'rgba(223, 77, 18, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 50) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 60) {
        backgroundColor = '#7c9d2b'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 70) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 80) {
        backgroundColor = '#000000'
        color = '#ffffff'
      }

      return `background-color:${backgroundColor}; color:${color};`
    }

    const exportData = flatRate => {
      let partner = ''
      if (typeof (partnerSelected.value.value) !== 'undefined') {
        partner = partnerSelected.value.value
      }

      let bonusStatusVal = 0
      if (typeof (statusSelected.value.value) !== 'undefined') {
        bonusStatusVal = statusSelected.value.value
      }

      let yearVal = currentYear
      if (typeof (year.value.value) !== 'undefined') {
        yearVal = year.value.value
      }

      store
        .dispatch(`${BONUS_APP_STORE_MODULE_NAME}/exportBonus`, {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          year: yearVal,
          status: status.value,
          vin: vin.value,
          partner,
          bonusStatus: bonusStatusVal,
          email: userEmail.value,
          flatRate,
        })
        .then(response => {
          // generate csv
          const d = new Date()
          const blob = createBlob([response.data], 'text/csv')
          const fileURL = URL.createObjectURL(blob)
          const anchor = document.createElement('a')
          anchor.href = fileURL
          anchor.target = '_blank'
          anchor.download = `${moment(d).format('YYYY_MM_DD_hhmmss')}_Bonus_list.csv`
          anchor.click()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const exportDataBackoffice = () => {
      let partner = ''
      if (typeof (partnerSelected.value.value) !== 'undefined') {
        partner = partnerSelected.value.value
      }

      let bonusStatusVal = 0
      if (typeof (statusSelected.value.value) !== 'undefined') {
        bonusStatusVal = statusSelected.value.value
      }

      let yearVal = currentYear
      if (typeof (year.value.value) !== 'undefined') {
        yearVal = year.value.value
      }

      store
        .dispatch(`${BONUS_APP_STORE_MODULE_NAME}/exportBonusBackoffice`, {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          year: yearVal,
          status: status.value,
          vin: vin.value,
          partner,
          bonusStatus: bonusStatusVal,
          email: userEmail.value,
        })
        .then(response => {
          // generate csv
          const d = new Date()
          const blob = createBlob([response.data], 'text/csv')
          const fileURL = URL.createObjectURL(blob)
          const anchor = document.createElement('a')
          anchor.href = fileURL
          anchor.target = '_blank'
          anchor.download = `${moment(d).format('YYYY_MM_DD_hhmmss')}_Bonus_list_backoffice.csv`
          anchor.click()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const historyOpenAi = bonusId => {
      openAIprocessData.value = { prompt: '', response: '' }
      openAIprocessActive.value = true

      store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/historyOpenAi`, { id: bonusId })
        .then(response => {
          openAIprocessData.value = response.data
          const responseArray = JSON.parse(response.data.response)
          responseArray.choices[0].message.content = responseArray.choices[0].message.content.replace(/(?:\r\n|\r|\n)/g, '<br>')
          openAIprocessData.value = {
            prompt: response.data.prompt,
            response: responseArray,
          }
          openAIprocessActive.value = false
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler bei der Verbindung zum Server',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
          openAIprocessActive.value = false
        })
    }

    watch([currentPage, perPage, searchQuery, partnerSelected, statusSelected, year, statusAiSelected], () => {
      refetchData()
    })

    watch([vin], () => {
      if ((vin.value.length >= 17) || (vin.value.length === 0)) {
        refetchData()
      }
    })

    watch([userEmail], () => {
      if ((userEmail.value.length > 5) || (userEmail.value.length === 0)) {
        refetchData()
      }
    })

    const compareDates = (date1, date2) => {
      if (date2.length > 0) {
        const dt1 = new Date(date1)
        const pattern = /(\d{2})\.(\d{2})\.(\d{4})/
        const dt2 = new Date(date2.replace(pattern, '$3-$2-$1'))

        if (dt1.getTime() < dt2.getTime()) {
          return date2
        }
      }

      return ''
    }

    const email2string = email => email.substring(0, email.indexOf('@'))

    return {
      // Sidebar
      isAddNewcarsidebarActive,

      fetchBonus,
      tableColumns,
      perPage,
      currentPage,
      totalBonus,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      vin,
      userEmail,
      partnerDD,
      yearDD,
      year,
      partnerSelected,
      statusDD,
      statusSelected,
      statusAiSelected,
      deleteBonus,
      bonusStatus,
      updateStatus,
      getStatusTitle,
      getStatusColor,
      exportData,
      exportDataBackoffice,
      reprocessOpenAi,
      historyOpenAi,
      openAIprocessActive,
      openAIprocessData,
      reprocessRemoveOpenAi,
      statusAi,
      compareDates,
      email2string,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

pre {
  white-space: pre-wrap;
  word-break: keep-all;
  max-height: 400px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
